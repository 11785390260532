import $ from 'jquery'

$(document).ready(function () {
  dhsv_vc_tile_list()
})

function dhsv_vc_tile_list() {
  $('.dhsv_vc_tile_list').each(function () {
    $('a.go').on('click', function (e) {
      e.preventDefault()
      let elementClick = $(this).attr('href')
      if ($(elementClick).length > 0) {
        let destination = $(elementClick).offset().top + 'px'
        $('body, html').animate({ scrollTop: destination }, 1500)
      }
    })
  })
}
