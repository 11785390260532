import $ from 'jquery'

$(document).ready(function () {
  dhsv_vc_accordion()
})

function dhsv_vc_accordion() {
  $('.accordion').each(function () {
    setTimeout(() => {
      $(this)
        /*.find('.accordion__item:first-child .accordion__item-title')*/
        .trigger('click')
    }, 200)
  })
}
