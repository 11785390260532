import 'jquery-match-height/dist/jquery.matchHeight-min'
import customModal from '../util/custom-modal'

function smoothScroll(link, e) {
  let destination = 0

  if (e) e.preventDefault()
  var hash = false
  if (!link && window.location.hash) {
    hash = window.location.hash
  } else if (link && link.indexOf('#') == 0) {
    hash = link
  } else if (
    link &&
    location.pathname.replace(/^\//, '') == link.pathname.replace(/^\//, '') &&
    location.hostname == link.hostname
  ) {
    hash = link.hash
  }

  if (hash && $(hash).length) {
    destination = $(hash).offset().top - 100
    history.replaceState(null, null, hash)
  }

  $('html, body').animate(
    {
      scrollTop: destination,
    },
    600
  )
}
function designReadMore() {
  const designArticles = document.querySelectorAll('.design')
  const readMoreBtns = document.querySelectorAll('.read-more')
  const MAX_CHAR_LENGTH = 140
  const designArticlesContent = []

  designArticles.forEach(article => {
    const text = article.querySelector('p')
    const readMoreBtn = article.querySelector('.read-more')
    designArticlesContent.push(text.innerHTML)
    if (text.innerHTML.length > MAX_CHAR_LENGTH) {
      readMoreBtn.style.display = 'flex'
      text.innerHTML = text.innerHTML.substring(0, MAX_CHAR_LENGTH) + '...'
    }
  })

  readMoreBtns.forEach((readMoreBtn, idx) => {
    const text = designArticles[idx].querySelector('p')
    readMoreBtn.addEventListener('click', () => {
      if (readMoreBtn.classList.contains('less')) {
        text.innerHTML = text.innerHTML.substring(0, MAX_CHAR_LENGTH) + '...'
        readMoreBtn.classList.remove('less')
        readMoreBtn.innerHTML = `
          ${readMoreBtn.dataset.more} <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.833344 0.333252L5.00001 4.49992L9.16668 0.333252H0.833344Z" fill="black" fill-opacity="0.6"/>
          </svg>
        `
      } else {
        text.innerHTML = designArticlesContent[idx]
        readMoreBtn.innerHTML = `
          ${readMoreBtn.dataset.less} <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.833313 4.66667L4.99998 0.5L9.16665 4.66667H0.833313Z" fill="black" fill-opacity="0.6"/>
          </svg>
        `
        readMoreBtn.classList.add('less')
      }
    })
  })
}

function mavenoidBotShow() {
  $('.dropdown-menu li').each(function () {
    if ($(this).hasClass('help')) {
      $(this)
        .find('a')
        .attr(
          'href',
          'javascript:mavenoid.push({ event: "troubleshooter-show" });'
        )
    }
  })

  $('.menu-footer-support-container li').each(function () {
    if ($(this).hasClass('help')) {
      $(this)
        .find('a')
        .attr(
          'href',
          'javascript:mavenoid.push({ event: "troubleshooter-show" });'
        )
    }
  })

  $('.cta-btn.help').each(function () {
    var $ctaBtn = $(this)
    var $link = $ctaBtn.find('a')
    $link.attr(
      'href',
      'javascript:mavenoid.push({ event: "troubleshooter-show" });'
    )
  })
}

function midasVerticalScroll() {
  const scrollItems = document.querySelectorAll('.gradient--midas-vertical')

  scrollItems.forEach(scrollItem => {
    const scrollSpeed = 0.3

    scrollItem.style.setProperty(
      '--y-offset',
      -scrollItem.offsetTop * scrollSpeed + 'px'
    )

    window.addEventListener('scroll', () => {
      let scrollPosition = window.pageYOffset

      scrollItem.style.setProperty(
        '--y-scroll',
        scrollPosition * scrollSpeed + 'px'
      )
    })
  })
}

function midasHorizontalScroll() {
  const scrollItems = document.querySelectorAll('.gradient--midas-horizontal')

  scrollItems.forEach(scrollItem => {
    const scrollSpeed = 0.5

    scrollItem.style.setProperty(
      '--x-offset',
      -scrollItem.offsetTop * scrollSpeed + 'px'
    )

    window.addEventListener('scroll', () => {
      let scrollPosition = window.pageYOffset

      scrollItem.style.setProperty(
        '--x-scroll',
        scrollPosition * scrollSpeed + 'px'
      )
    })
  })
}

function violaVerticalScroll() {
  const scrollItems = document.querySelectorAll('.gradient--voila')

  scrollItems.forEach(scrollItem => {
    const scrollSpeed = 0.3

    scrollItem.style.setProperty(
      '--y-offset',
      -scrollItem.offsetTop * scrollSpeed + 'px'
    )

    window.addEventListener('scroll', () => {
      let scrollPosition = window.pageYOffset

      scrollItem.style.setProperty(
        '--y-scroll',
        scrollPosition * scrollSpeed + 'px'
      )
    })
  })
}

export default {
  init() {
    // JavaScript to be fired on all pages

    var windowSize = $(window).width()

    $('.matchHeight').matchHeight()

    if ($(window).width() > 767) {
      $('.navbar .dropdown').hover(
        function () {
          $(this).addClass('active')
          $(this).find('.dropdown-menu').first().addClass('active')
        },
        function () {
          $(this).removeClass('active')
          $(this).find('.dropdown-menu.active').first().removeClass('active')
        }
      )

      $('.navbar .collapse.navbar-collapse .dropdown > a').click(function () {
        location.href = this.href
      })
    }

    dhsv_header_scroll_detect()
    $(window).on('scroll', function () {
      // jQuery code goes here
      dhsv_header_scroll_detect()
    })

    dhsv_header_scroll_down_footer_detect()

    dhsv_header_scroll_up_detect()

    $('.dhsv-menu-close').on('click', function () {
      if ($(window).width() > 974) {
        let dropdown = $(this).closest('.dropdown-container')
        dropdown.removeClass('opened')
      }
    })

    $('.nav-item.dropdown.main').on('mouseenter', function () {
      if ($(window).width() > 974) {
        let dropdown = $(this).find('.dropdown-container')
        dropdown.addClass('opened')
      }
    })

    $('.nav-item.dropdown.main').on('mouseleave', function () {
      if ($(window).width() > 974) {
        let dropdown = $(this).find('.dropdown-container')
        dropdown.removeClass('opened')
      }
    })

    $('.nav-item.dropdown.main > a').on('click tap', function (e) {
      if ($(window).width() < 975) {
        e.preventDefault()
        let dropdown = $(this).parent().find('.dropdown-container')
        dropdown.toggleClass('opened')
      }
    })

    function dhsv_header_scroll_detect() {
      let scrolled = $(window).scrollTop()

      if (scrolled > 0) {
        $('.maindropdowns').addClass('scrolled')
      } else {
        $('.maindropdowns').removeClass('scrolled')
      }
    }

    function dhsv_header_scroll_up_detect() {
      var prevScroll = $(document).scrollTop()

      $(window).scroll(function () {
        var newScroll = $(document).scrollTop()
        if (newScroll < prevScroll) {
          // Scrolled up
          $('.maindropdowns').removeClass('scrolled')
        } else {
          // Scrolled down
          $('.maindropdowns').addClass('scrolled')
        }
        prevScroll = newScroll
      })
    }

    function dhsv_header_scroll_down_footer_detect() {
      var distance = $('footer.footer').offset().top,
        $window = $(window),
        headerheight = $('header.banner').outerHeight(),
        $prevfooter = $('footer.footer')
          .prev()
          .find('section')
          .last()
          .find('.dhsv_vc_header')
      // if there is the header module in a section right before the footer calc offset from there
      if ($prevfooter.length) {
        distance = $($prevfooter).offset().top
      }
      //console.log('distance: ' + distance)
      $window.scroll(function () {
        headerheight = $('header.banner').outerHeight()
        if ($window.scrollTop() + headerheight >= distance) {
          //console.log('footer reached top!')
          $('header.banner').addClass('showfooter')
        } else {
          $('header.banner').removeClass('showfooter')
        }
      })
    }

    $('.navbar-toggler').on('click', function () {
      $(this).toggleClass('collapsed')
      $('html, body').toggleClass('menuopen')
      $('.offcanvas-menu').toggleClass('is-active')
    })

    if (windowSize <= 974) {
      $('.offcanvas-menu ul li.menu-item-has-children > a').click(function (e) {
        e.preventDefault()
        $(this).parent().toggleClass('show')
        $(this).parent().find('.dropdown-menu').toggleClass('show')
      })
    }

    $('body').on('click', '#back-to-top', function (e) {
      e.preventDefault()
      $('html, body').animate({ scrollTop: 0 })
    })

    $(window).scroll(function () {
      if ($(window).scrollTop() > $(document).height() / 2) {
        $('#back-to-top').addClass('visible')
      } else {
        $('#back-to-top').removeClass('visible')
      }
    })

    $('body').on(
      'click',
      'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"],[data-vc-accordion],[data-vc-tabs])',
      function (e) {
        smoothScroll($(this).attr('href'), e)
      }
    )

    $('.close-modal, .modal:not(.bootstrap-modal)').on('click', function () {
      var modals = $('div.modal')
      modals.each(function () {
        $(this)[0].classList.remove('show')
        $(this)[0].classList.remove('collapse')
      })
    })

    $('.program_modal__collaps').on('click', function () {
      $(this).next('.modal').addClass('show')
    })

    // Video Leads Form
    const handleClick = e => {
      let $container = e
      if (e.target) {
        $container = e.target
      }
      const classList = $container.classList
      if (!classList.contains('js-video-leads')) {
        $container = $container.closest('.js-video-leads')
      }
      if (!$container.classList.contains('clicked')) {
        const videoId = $container.getAttribute('data-video-id')
        const secondsToOpen = $container.getAttribute('data-to-open')
        if (
          videoId &&
          secondsToOpen &&
          !localStorage.getItem('videoLeadsFormSubmitted_' + videoId)
        ) {
          setTimeout(function () {
            $('#' + videoId).modal('show')
            $container.classList.add('clicked')
          }, Number(secondsToOpen) * 1000)
        }
      }
    }

    /* check if HubSpot form for the video is already submited */
    const videoLeads = document.querySelectorAll('.js-video-leads')
    videoLeads.forEach(e => {
      const videoId = e.getAttribute('data-video-id')
      if (!localStorage.getItem('videoLeadsFormSubmitted_' + videoId)) {
        $('body').on(
          'click',
          '.js-video-leads[data-video-id="' + videoId + '"]',
          handleClick
        )
      }
    })

    window.addEventListener('blur', () => {
      const activeElement = document.activeElement
      if (activeElement.matches('iframe, embed')) {
        handleClick(activeElement.closest('.js-video-leads'))
      }
    })

    setTimeout(function () {
      const leadsForm = document.querySelectorAll('.video-leads-modal')
      leadsForm.forEach(e => {
        const formId = e.closest('.video-leads-modal').getAttribute('id')
        const form = e.querySelector('form')
        form.addEventListener('hsvalidatedsubmit', function () {
          localStorage.setItem('videoLeadsFormSubmitted_' + formId, true)
          $('body').off(
            'click',
            '.js-video-leads[data-video-id="' + formId + '"]',
            handleClick
          )
        })
      })
    }, 1000)

    midasVerticalScroll()
    midasHorizontalScroll()
    violaVerticalScroll()
    mavenoidBotShow()
    designReadMore()
    customModal()
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
