import $ from 'jquery'

$(document).ready(function () {
  dhsv_vc_material_info()
})

// $(document).ready(function () {})

$(window).on('scroll', function () {
  // jQuery code goes here
  dhsv_vc_material_info()
})

function dhsv_vc_material_info() {
  let scrollTop = $(document).scrollTop(),
    triggerPoint = scrollTop + window.innerHeight / 2

  $('.dhsv_vc_material_info').each(function () {
    let offsetTop = $(this).offset().top,
      $bars = $(this).find('.js-animated-bar')

    if (triggerPoint >= offsetTop && !$(this).hasClass('animated')) {
      $(this).addClass('animated')
      $bars.each(function () {
        let width = $(this).attr('data-width')
        $(this).animate({ width: width + '%' }, 500)
      })
    }
  })
}
