import $ from 'jquery'

$(document).ready(function () {
  addBoxShadow()
  $('.dhsv_vc_table_list').each(function () {
    const $parent = $(this)
    const $el = $(this).find('.dhsv_vc_table_list__container')
    $el.on('scroll', function () {
      var horizontalScrollbarPosition = $(this).scrollLeft()

      // Check if scrollbar is at the very right
      if (
        horizontalScrollbarPosition + $el.innerWidth() >=
        $el[0].scrollWidth
      ) {
        $parent.addClass('hide-shadow')
      } else {
        $parent.removeClass('hide-shadow')
      }
    })
  })
})

$(window).on('resize', function () {
  addBoxShadow()
})

function addBoxShadow() {
  $('.dhsv_vc_table_list').each(function () {
    if ($(this).hasClass('with-shadow')) {
      $(this).removeClass('with-shadow')
    }
    const currentWidth = $(this).width()
    if (currentWidth < 650) {
      $(this).addClass('with-shadow')
    } else {
      $(this).removeClass('with-shadow')
    }
  })
}
