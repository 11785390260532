import $ from 'jquery'

$(document).ready(function () {
  dhsv_vc_platform()
  designReadMore()
})

function designReadMore() {
  const designArticles = document.querySelectorAll('.js-design')
  const readMoreBtns = document.querySelectorAll('.js-read-more')
  const MAX_CHAR_LENGTH = 140
  const designArticlesContent = []

  designArticles.forEach(article => {
    const text = article.querySelector('p')
    const readMoreBtn = article.querySelector('.js-read-more')
    designArticlesContent.push(text.innerHTML)
    if (text.innerHTML.length > MAX_CHAR_LENGTH) {
      readMoreBtn.style.display = 'flex'
      text.innerHTML = text.innerHTML.substring(0, MAX_CHAR_LENGTH) + '...'
    }
  })

  readMoreBtns.forEach((readMoreBtn, idx) => {
    const text = designArticles[idx].querySelector('p')
    readMoreBtn.addEventListener('click', () => {
      if (readMoreBtn.classList.contains('less')) {
        text.innerHTML = text.innerHTML.substring(0, MAX_CHAR_LENGTH) + '...'
        readMoreBtn.classList.remove('less')
        readMoreBtn.innerHTML = `
              ${readMoreBtn.dataset.more} <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.833344 0.333252L5.00001 4.49992L9.16668 0.333252H0.833344Z" fill="black" fill-opacity="0.6"/>
              </svg>
            `
      } else {
        text.innerHTML = designArticlesContent[idx]
        readMoreBtn.innerHTML = `
              ${readMoreBtn.dataset.less} <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.833313 4.66667L4.99998 0.5L9.16665 4.66667H0.833313Z" fill="black" fill-opacity="0.6"/>
              </svg>
            `
        readMoreBtn.classList.add('less')
      }
    })
  })
}

function dhsv_vc_platform() {
  $('.dhsv_vc_platform').each(function () {})
}
