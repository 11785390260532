import $ from 'jquery'

$(document).ready(function () {
  $('.number').each(function () {
    var defaultDataElement = $(this)
    var number = defaultDataElement.data('number')

    var endNumber = parseInt(number)
    var duration = 2000
    var steps = 50

    var step = endNumber / steps
    var currentNumber = 0

    var countingInterval = setInterval(function () {
      currentNumber += step
      defaultDataElement.text(Math.round(currentNumber))

      if (currentNumber >= endNumber) {
        clearInterval(countingInterval)
        defaultDataElement.text(endNumber)
      }
    }, duration / steps)
  })
})
