import $ from 'jquery'

$(document).ready(dhsv_vc_calculator_roi())

function dhsv_vc_calculator_roi() {
  $(
    '.dhsv_vc_calculator_roi__material .dhsv_vc_calculator_roi__material__range'
  ).on('change input', function () {
    $(this)
      .next('.dhsv_vc_calculator_roi__material__range_count')
      .text($(this).val())
  })
}
