import $ from 'jquery'
import customModal from '../../scripts/util/custom-modal'

$(document).ready(function () {
  dhsv_vc_hero()
})

function dhsv_vc_hero() {
  $('.dhsv_vc_hero').each(function () {
    customModal()
  })
}
